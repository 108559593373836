import React, { Component } from 'react'
import Helmet from 'react-helmet'

import Header from './header'
import { Scroll } from './icons'

class Landing extends Component {

  state = {
    fixed: null,
    opacity: 1,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
    let { fixed } = this.state

    //let opacity = 1 - (currentScroll - (window.innerHeight / 2)) / window.innerHeight
    //this.setState({ opacity: opacity })

    if (currentScroll >= window.innerHeight) {
      if (fixed) {
        this.setState({ fixed: false })
      }
    } else {
      if (!fixed) {
        this.setState({ fixed: true })
      }
    }
  }

  render() {
    let { fixed } = this.state
    let { settings, main } = this.props

    let bodyClass = fixed ? 'landing__fixed home' : 'home'

    let $content = typeof document !== 'undefined' && document.querySelector('.site__content--home')
    let $footer = typeof document !== 'undefined' && document.querySelector('.footer')
    let contentHeight = $content && $footer ? $content.scrollHeight + $footer.scrollHeight : 0
    let bodyHeight = contentHeight + 'px'

    const getBodyStyleAttribute = (bodyHeight) => {
      if (typeof window !== 'undefined') {
        return [
          bodyHeight && `padding-bottom: ${fixed ? bodyHeight : 0};`,
        ].filter(s => s).join('')
      }
      return { paddingBottom: fixed ? bodyHeight : 0 }
    }
    return (
      <>
      <Helmet>
        <body className={bodyClass} style={getBodyStyleAttribute(bodyHeight)} />
      </Helmet>
      <section className="landing">
        <Header background_color="transparent" main={main} />
        { settings.background_image && <img src={settings.background_image.localFile.childImageSharp.original.src} alt="FSC Group - Landing Banner" /> }
        <div className="landing__content" dangerouslySetInnerHTML={{ __html: settings.content }} />
        <div className="landing__inner container">
          <h3 dangerouslySetInnerHTML={{ __html: settings.title }} />
        </div>
        <div className="landing__scroll"><Scroll /> <span>Scroll Down</span></div>
      </section>
      </>
    )
  }
}

Landing.defaultProps = {
  settings: {},
  main: {},
}

export default Landing