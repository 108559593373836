import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../components/header'
import Landing from '../components/landing'
import Footer from '../components/footer'

import '../assets/scss/main.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        menu: allWordpressWpApiMenusMenusItems (filter: {slug: {eq: "main"}}) {
          edges {
            node {
              items {
                title
                url
                type
              }
            }
          }
        }
        footer_1: allWordpressWpApiMenusMenusItems (filter: {slug: {eq: "footer-1"}}) {
          edges {
            node {
              items {
                title
                url
                type
              }
            }
          }
        }
        footer_sectors: allWordpressWpApiMenusMenusItems (filter: {slug: {eq: "footer-sectors"}}) {
          edges {
            node {
              items {
                title
                url
                type
              }
            }
          }
        }
        settings: allWordpressAcfOptions {
          edges {
            node {
              options {
                instagram
                linkedin
                background_image {
                  localFile {
                    childImageSharp {
                      original {
                        src
                      }
                    }
                  }
                }
                title
                content
              }
            }
          }
        }
      }
    `}
    render={data => {
      let { slug, type } = children.props.pageContext

      let wrapClass = 'site__content'
      if (slug === 'home') wrapClass += ' site__content--home'
      if (type === 'landing') wrapClass += ' site__content--landing'

      return (
        <>
          { children.props.pageContext.slug === 'home' &&
            <Landing settings={data.settings.edges[0].node.options} main={data.menu} />
          }
          <div className={ wrapClass }>
            <Header main={data.menu} />
            <main className = {wrapClass}>
              { children }
            </main>
            <Footer settings={data.settings.edges[0].node.options} footer_one={data.footer_1} footer_sectors={data.footer_sectors} />
          </div>
        </>
      )
    }
    }
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout