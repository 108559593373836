import { Link } from 'gatsby'
import React, { Component } from 'react'

import Logo from '../components/logo'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
    let { scrolled } = this.state

    let nextState = currentScroll > 0
    if (nextState !== scrolled) this.setState({ scrolled: nextState })
  }

  render() {

    let { offCanvas, scrolled } = this.state

    let headerClass = `header`
    let color = '#2a2728'

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let { main } = this.props

    if (offCanvas) {
      headerClass += ' header--inverse'
      color = '#fff'
    }
    if (scrolled) {
      headerClass += ' header--scrolled'
    }

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner container'>
            <Link to='/' title='FSC Group' className='header__logo' {...props}>
              <Logo color={color} />
            </Link>
            <div className="header__right">
              <ul>
                { main.edges && main.edges[0].node.items.map((el, i) => {
                  return (
                    <li key={i}>
                      <Link to={el.url} {...props}>{el.title}</Link>
                    </li>
                  )
                }) }
              </ul>
              <button id="burger" onClick={this._toggleOffCanvas} className={ this.state.offCanvas ? 'active' : '' }>
                <span className="text">Explore</span>
                <span className="lines"></span>
              </button>
            </div>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <nav>
            <ul>
                { main.edges && main.edges[0].node.items.map((el, i) => {
                  return (
                    <li key={i}>
                      <Link to={el.url} {...props}>{el.title}</Link>
                    </li>
                  )
                }) }
            </ul>
          </nav>
        </div>
      </>
    )
  }
}

Header.defaultProps = {
  siteTitle: ``,
  main: {},
}

export default Header
